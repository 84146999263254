import { createTheme } from "@mui/material"
import {
  COLORS,
  BASE_THEME,
  GLASS_LIGHT_DEFAULT,
  GLASS_BASE_THEME,
  RADIUS,
  GLASS_V1,
  GLASS_V2,
} from "../themes/constants"

export const PRIMARY = createTheme({
  ...BASE_THEME,
  palette: {
    primary: {
      main: "#65BE8D",
      light: "#97F1BD",
      dark: "#328D60",
      contrastText: COLORS.white,
    },
  },
})

export const GREY = createTheme({
  ...BASE_THEME,
  palette: {
    primary: {
      main: "#C7C7C7",
      contrastText: COLORS.black.primary,
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: RADIUS,
          textTransform: "none",
          boxShadow:
            "0px 2px 10px rgba(199, 199, 199, 0.2), 0px 3px 8px rgba(199, 199, 199, 0.25), 0px 4px 5px -4px rgba(199, 199, 199, 0.25)",
        },
      },
    },
  },
})

export const WHITE = createTheme({
  ...BASE_THEME,
  palette: {
    primary: {
      main: COLORS.white,
      contrastText: COLORS.black.primary,
    },
  },
})

export const GLASS_LIGHT = createTheme({
  ...BASE_THEME,
  ...GLASS_BASE_THEME,
  components: {
    ...BASE_THEME.components,
    ...GLASS_BASE_THEME.components,
    MuiAppBar: {
      styleOverrides: {
        root: {
          /* From https://css.glass */
          background: "rgba(0, 0, 0, 0.26)",
          borderRadius: "16px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          ...GLASS_LIGHT_DEFAULT,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          ...GLASS_LIGHT_DEFAULT,
          borderRadius: RADIUS,
          color: COLORS.white,
          padding: 16,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...GLASS_LIGHT_DEFAULT,
        },
      },
    },
  },
})

export const DEFAULT = createTheme({
  ...BASE_THEME,
  palette: {
    primary: {
      main: "#65BE8D",
      light: "#97F1BD",
      dark: "#328D60",
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.white,
      light: "#97F1BD",
      dark: "#328D60",
      contrastText: COLORS.white,
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ...GLASS_V2,
          borderRadius: 8,
          ":hover": {
            ...GLASS_V1,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          ...GLASS_V1,
          borderRadius: 8,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...GLASS_V2,
        },
      },
    },
  },
})
